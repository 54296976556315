import { useContext, useEffect, useState } from "react";
//API
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
//Redux
import { useSelector } from "react-redux";
import Portals from "./models/Portals";
import PortalsWifi from "./models/PortalsWifi";
import { setWifiSettings as updateWifiSettings, WIFI_SETTINGS } from "./utils/networkQueries";
import { NetworkSettingsContext } from "contexts/NetworkSettings";
import { escapeSpecialChars } from "hooks/Utils/Utils";
import { toast } from "react-toastify";
import { Session } from "hooks/Utils/Session";
import { useModal } from "components/Modal";

export const useWifiConfig = () => {
    const [portalsWS, setPortalsWS] = useState([]);
    const [portalsWifi, setPortalsWifi] = useState([]);

    const superUser = Session.getSessionProp("superUser");
    const { wifiSettings, setWifiSettings } = useContext(NetworkSettingsContext);
    const { open } = useModal();
    const { permissions } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const wifiProductEnabled = permissions?.hotel?.wifiConfig;

    const {
        data: dataWS,
        loading: loadingWS,
        error: errorWS,
        called,
    } = useQuery(WIFI_SETTINGS, {
        pollInterval: 0,
        fetchPolicy: "network-only",
    });
    const [executeMutation] = useMutation(updateWifiSettings);

    const arrangeDataWS = () => {
        let tempArray = [];
        // eslint-disable-next-line
        Object.keys(dataWS).forEach((element) => {
            tempArray[element] = { ...dataWS[element] };
        });
        return Portals(tempArray, superUser, open, t);
    };

    useEffect(() => {
        if (wifiProductEnabled) {
            setPortalsWifi(PortalsWifi(t));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loadingWS && !errorWS && dataWS) {
            const portalsDataWS = arrangeDataWS();
            setPortalsWS(portalsDataWS);
            setWifiSettings({
                hotelwifi: {
                    name: portalsDataWS[0]?.fields[0]?.value,
                    password: portalsDataWS[0]?.fields[1]?.value,
                },
            });
        }
        // eslint-disable-next-line
    }, [dataWS]);

    useEffect(() => {
        if (wifiSettings["hotelwifi"]?.save) {
            executeMutation({
                variables: {
                    name: wifiSettings["hotelwifi"]?.name,
                    password: escapeSpecialChars(wifiSettings["hotelwifi"]?.password),
                },
            }).then((response) => {
                if (response.data.setWifiSettings.ok) {
                    toast.success(t("operation-successful"));
                    setWifiSettings({
                        hotelwifi: {
                            ...wifiSettings["hotelwifi"],
                            save: false,
                        },
                    });
                }
            });
        }
    }, [wifiSettings]);

    return { portalsWS, portalsWifi, ready: !loadingWS && called };
};
