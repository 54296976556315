import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Select from "components/Select";
import { useDevicesActions } from "hooks/GraphqlCalls/Hotel/Monitoring/useDevicesActions";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import Radiobuttons from "components/Inputs/Radiobuttons";
import { toast } from "react-toastify";
import { DEVICE_TYPES } from "hooks/Utils/Hotel/Monitor/ListDevicesUtils";
import { wait } from "hooks/GraphqlCalls/Hotel/utils/monitorUtils";

export const Assign = ({ name, reference, refetch }) => {
    const [validation, setValidation] = useState("");
    const [roomTVID, setRoomTVID] = useState(null);
    const { close } = useModal();
    const { t } = useTranslation();
    const { assignDevice, getRooms, rooms, loading } = useDevicesActions();

    useEffect(() => {
        getRooms();
    }, []);

    return (
        <Modal
            id="assign"
            title={`${name} - ${t("assign")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-hide"
                        onClick={() => {
                            if (roomTVID) {
                                assignDevice(reference, roomTVID, "CAST");
                                close();
                                wait(refetch, 500);
                                toast.success(t("operation-successful"));
                            } else {
                                setValidation({ validation: "border border-red-100" });
                                toast.error(t("errors-in-red"));
                            }
                        }}
                    >
                        {t("assign")}
                    </Button>
                </div>
            }
        >
            <p className="mt-4">
                {t("select-room-location-assign")} {name}
            </p>
            <SelectRoom rooms={rooms} validation={validation} setRoomTVID={setRoomTVID} />
            <div className="flex items-center gap-2 mt-4">
                <p>
                    {t("device-function")}: {t("chromecast")}
                </p>
            </div>
        </Modal>
    );
};

export const Delete = ({ name, reference, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { deleteDevice } = useDevicesActions();

    return (
        <Modal
            id="delete-cc"
            title={`${name} - ${t("delete")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="red"
                        id="modal-button-delete"
                        onClick={() => {
                            deleteDevice(reference);
                            close();
                            wait(refetch, 500);
                        }}
                    >
                        {t("delete")}
                    </Button>
                </div>
            }
        >
            <p>
                {t("delete-device-in-room-confirm-without-room", {
                    name,
                })}
            </p>
        </Modal>
    );
};

export const AuthorizeAndAssign = ({ name, model, type, reference, mac, refetch, deviceType, deviceFunction }) => {
    const [validation, setValidation] = useState("");
    const [roomTVID, setRoomTVID] = useState(null);
    const [fn, setFn] = useState(deviceFunction || deviceType === DEVICE_TYPES.philips.value ? "TVCAST" : "CAST");
    const { close } = useModal();
    const { t } = useTranslation();
    const { authoriseDevices, getRooms, rooms, loading } = useDevicesActions();

    useEffect(() => {
        getRooms();
    }, []);

    return (
        <Modal
            id="authorise-and-assign"
            title={`${name} - ${t("authorise-and-assign")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-hide"
                        onClick={() => {
                            if (roomTVID) {
                                authoriseDevices(reference, mac, type, model, name, fn, roomTVID, true);
                                close();
                                wait(refetch, 500);
                                toast.success(t("operation-successful"));
                            } else {
                                setValidation({ validation: "border border-red-100" });
                                toast.error(t("errors-in-red"));
                            }
                        }}
                    >
                        {t("authorise-and-assign")}
                    </Button>
                </div>
            }
        >
            <p>
                {t("devices-authorise-text", {
                    name,
                })}
            </p>
            {deviceType === DEVICE_TYPES.googleTV.value && (
                <div className="my-6">
                    <SelectFunction fn={fn} setFn={setFn} />
                    <p className="mt-4 text-sm">{t("device-assign-type-function")}</p>
                </div>
            )}
            <p className="mt-4">
                {t("select-room-location-assign")} {name}
            </p>
            <SelectRoom rooms={rooms} validation={validation} setRoomTVID={setRoomTVID} />
            {deviceType === DEVICE_TYPES.chromecast.value ? (
                <div className="flex items-center gap-2 mt-4">
                    <p>
                        {t("device-function")}: {t("chromecast")}
                    </p>
                </div>
            ) : (
                <div className="flex items-center gap-2 mt-4">
                    <p>
                        {t("device-function")}: {t("chromecast-and-stb")}
                    </p>
                </div>
            )}
        </Modal>
    );
};

export const Authorize = ({ name, model, type, reference, mac, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { authoriseDevices } = useDevicesActions();

    return (
        <Modal
            id="authorise"
            title={`${name} - ${t("authorise")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-hide"
                        onClick={() => {
                            authoriseDevices(reference, mac, type, model, name);
                            close();
                            wait(refetch, 500);
                        }}
                    >
                        {t("authorise")}
                    </Button>
                </div>
            }
        >
            <p>
                {t("devices-authorise-text", {
                    name,
                })}
            </p>
        </Modal>
    );
};

export const ShowStatus = ({ reference, name, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { toogleStatus } = useDevicesActions();

    return (
        <Modal
            id="hide-status"
            title={`${name} - ${t("chromecast-disable")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-hide"
                        onClick={() => {
                            toogleStatus(reference, true);
                            close();
                            wait(refetch, 500);
                            toast.success(t("operation-successful"));
                        }}
                    >
                        {t("chromecast-enable")}
                    </Button>
                </div>
            }
        >
            <p
                dangerouslySetInnerHTML={{
                    __html: t("device-show-status-confirmation", {
                        device: name,
                    }),
                }}
            ></p>
        </Modal>
    );
};

export const HideStatus = ({ reference, name, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { toogleStatus } = useDevicesActions();

    return (
        <Modal
            id="hide-status"
            title={`${name} - ${t("chromecast-disable")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-hide"
                        onClick={() => {
                            toogleStatus(reference, false);
                            close();
                            wait(refetch, 500);
                            toast.success(t("operation-successful"));
                        }}
                    >
                        {t("chromecast-disable")}
                    </Button>
                </div>
            }
        >
            <p
                dangerouslySetInnerHTML={{
                    __html: t("chromecasts-disable-text", {
                        name,
                    }),
                }}
            ></p>
        </Modal>
    );
};

export const EditName = ({ name, reference, refetch }) => {
    const [newName, setNewName] = useState(name);
    const { close } = useModal();
    const { t } = useTranslation();
    const { editName } = useDevicesActions();

    return (
        <Modal
            id="edit-name"
            title={`${name} - ${t("edit-name")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-edit"
                        onClick={() => {
                            editName(reference, newName);
                            close();
                            wait(refetch, 500);
                            toast.success(t("operation-successful"));
                        }}
                    >
                        {t("save")}
                    </Button>
                </div>
            }
        >
            <p>{t("device-edit-name-text")}</p>
            <TextInput
                id="edit-name-input"
                type="text"
                value={newName}
                onChange={(value) => setNewName(value)}
                className="w-full mt-4"
            />
        </Modal>
    );
};

export const MoveRoom = ({ name, reference, roomNumber, refetch, deviceFunction }) => {
    const [validation, setValidation] = useState("");
    const [roomTVID, setRoomTVID] = useState(null);
    const { close } = useModal();
    const { t } = useTranslation();
    const { getRooms, rooms, loading, moveRoom } = useDevicesActions();

    useEffect(() => {
        getRooms();
    }, []);

    return (
        <Modal
            id="move-room"
            title={`${name} - ${t("move-room")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-move"
                        onClick={() => {
                            if (roomTVID) {
                                moveRoom(reference, roomTVID, roomNumber);
                                close();
                                wait(refetch, 500);
                                toast.success(t("operation-successful"));
                            } else {
                                setValidation({ validation: "border border-red-100" });
                                toast.error(t("errors-in-red"));
                            }
                        }}
                    >
                        {t("move")}
                    </Button>
                </div>
            }
        >
            <p className="my-4">
                {t("select-roomtv-text")} {name}
            </p>
            <SelectRoom rooms={rooms} validation={validation} setRoomTVID={setRoomTVID} />
            <div className="flex items-center gap-2 mt-4">
                <p>
                    {t("device-function")}: {t(getDeviceFunctionText(deviceFunction))}
                </p>
                <Icon type="info" size="2xl" tooltip={t("chromecast-assign-stb-tooltip")} />
            </div>
        </Modal>
    );
};

export const TurnOff = ({ reference, name, location, roomNumber, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { turnOffOnDevice } = useDevicesActions();

    return (
        <Modal
            id="turn-off"
            title={`${name} - ${t("tv-power-off")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-turn-off"
                        onClick={() => {
                            turnOffOnDevice(reference, "POWEROFF");
                            close();
                            wait(refetch, 500);
                        }}
                    >
                        {t("tv-power-off")}
                    </Button>
                </div>
            }
        >
            <p
                dangerouslySetInnerHTML={{
                    __html: t("power-off-tv", {
                        location,
                        roomNumber,
                    }),
                }}
            ></p>
        </Modal>
    );
};

export const Reload = ({ reference, name, kind, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { reloadInterface } = useDevicesActions();

    return (
        <Modal
            id="reload"
            title={`${name} - ${t("tv-reload")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-reload"
                        onClick={() => {
                            reloadInterface(reference, kind);
                            close();
                            wait(refetch, 500);
                            toast.success(t("operation-successful"));
                        }}
                    >
                        {t("tv-reload")}
                    </Button>
                </div>
            }
        >
            <p
                dangerouslySetInnerHTML={{
                    __html: t("reload-interface-text", {
                        name,
                    }),
                }}
            ></p>
        </Modal>
    );
};

export const TurnON = ({ reference, name, location, roomNumber, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { turnOffOnDevice } = useDevicesActions();

    return (
        <Modal
            id="turn-on"
            title={`${name} - ${t("tv-power-on")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-turn-on"
                        onClick={() => {
                            turnOffOnDevice(reference, "POWERON");
                            close();
                            wait(refetch, 500);
                        }}
                    >
                        {t("tv-power-on")}
                    </Button>
                </div>
            }
        >
            <p
                dangerouslySetInnerHTML={{
                    __html: t("power-on-tv", {
                        location,
                        roomNumber,
                    }),
                }}
            ></p>
        </Modal>
    );
};

export const ChangeDeviceFunction = ({ reference, name, refetch, deviceFunction }) => {
    const [fn, setFn] = useState(deviceFunction || "CAST");
    const { close } = useModal();
    const { t } = useTranslation();
    const { changeDeviceFunction } = useDevicesActions();

    return (
        <Modal
            id="change-device-function"
            title={`${name} - ${t("change-device-function")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-cancel"
                        onClick={() => {
                            changeDeviceFunction(reference, fn);
                            close();
                            wait(refetch, 500);
                            toast.success(t("operation-successful"));
                        }}
                    >
                        {t("save")}
                    </Button>
                </div>
            }
        >
            <p className="my-4">{t("select-device-function")}:</p>
            <SelectFunction fn={fn} setFn={setFn} />
            <p className="mt-4 text-sm">{t("device-assign-type-function")}</p>
        </Modal>
    );
};

export const AssignGGTV = ({ reference, name, refetch, deviceFunction, type }) => {
    const [validation, setValidation] = useState("");
    const [roomTVID, setRoomTVID] = useState(null);
    const [fn, setFn] = useState(deviceFunction || type === DEVICE_TYPES.philips.value ? "TVCAST" : "CAST");
    const { close } = useModal();
    const { t } = useTranslation();
    const { assignDevice, getRooms, rooms, loading } = useDevicesActions();

    useEffect(() => {
        getRooms();
    }, []);

    return (
        <Modal
            id="change-device-function"
            title={`${name} - ${t("assign")}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-cancel"
                        onClick={() => {
                            if (roomTVID) {
                                assignDevice(reference, roomTVID, fn);
                                close();
                                wait(refetch, 500);
                            } else {
                                setValidation({ validation: "border border-red-100" });
                                toast.error(t("errors-in-red"));
                            }
                        }}
                    >
                        {t("save")}
                    </Button>
                </div>
            }
        >
            {type !== DEVICE_TYPES.philips.value ? (
                <>
                    <p className="my-4">{t("select-device-function")}:</p>
                    <SelectFunction fn={fn} setFn={setFn} />
                    <p className="my-4 text-sm">{t("device-assign-type-function")}</p>
                </>
            ) : null}
            <p className="mt-4">
                {t("select-room-location-assign")} {name}
            </p>
            <SelectRoom rooms={rooms} validation={validation} setRoomTVID={setRoomTVID} />
            {type === DEVICE_TYPES.philips.value && (
                <div className="flex items-center gap-2 mt-4">
                    <p>
                        {t("device-function")}: {t("chromecast-and-stb")}
                    </p>
                </div>
            )}
        </Modal>
    );
};

const SelectFunction = ({ fn, setFn }) => {
    const { t } = useTranslation();
    return (
        <div>
            <Radiobuttons
                options={[
                    { value: "CAST", label: t("chromecast") },
                    { value: "TV", label: `${t("tv-device")}*` },
                    { value: "TVCAST", label: `${t("chromecast-and-stb")}*` },
                ]}
                checked={fn}
                onChange={(value) => setFn(value)}
            />
        </div>
    );
};

const SelectRoom = ({ rooms, validation, setRoomTVID }) => {
    const { t } = useTranslation();

    const selectOptions = rooms?.data?.map((room, index) => {
        return {
            id: room?.roomTVID,
            value: room?.roomTVID,
            label: `${room?.roomName} - ${room?.roomTVName}`,
        };
    });

    return (
        <Select
            id="select-room"
            placeholder={t("select")}
            optionsContainerClass="w-full"
            designClass={validation}
            options={selectOptions}
            onChange={(value) => setRoomTVID(value)}
        />
    );
};

export const MonitorActions = ({
    id,
    title,
    buttonText,
    mutation,
    mutationProps,
    dangerousHTML,
    primaryText,
    secondaryText,
    textVariables,
    refetch,
    icon,
    iconText,
}) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { name, roomNumber, roomTVName } = textVariables;
    const {
        unassignDevice,
        detachFromProperty,
        deauthorizeDevice,
        restartDevice,
        factoryReset,
        uninstallTVDevice,
        pairingRemoteControl,
        launchSettingsScreen,
        launchSystemSettingsScreen,
    } = useDevicesActions();

    let mutationFunction;

    switch (mutation) {
        case "unassign":
            mutationFunction = unassignDevice;
            break;
        case "detachFromProperty":
            mutationFunction = detachFromProperty;
            break;
        case "deauthorizeDevice":
            mutationFunction = deauthorizeDevice;
            break;
        case "restartDevice":
            mutationFunction = restartDevice;
            break;
        case "factoryReset":
            mutationFunction = factoryReset;
            break;
        case "uninstallTVDevice":
            mutationFunction = uninstallTVDevice;
            break;
        case "pairingRemoteControl":
            mutationFunction = pairingRemoteControl;
            break;
        case "launchSettingsScreen":
            mutationFunction = launchSettingsScreen;
            break;
        case "launchSystemSettingsScreen":
            mutationFunction = launchSystemSettingsScreen;
            break;
        default:
            throw new Error(`Unknown mutation: ${mutation}`);
    }

    return (
        <Modal
            id={id}
            title={`${name} - ${t(title)}`}
            className="w-1/3 p-10"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-unassign"
                        onClick={() => {
                            mutationFunction(mutationProps);
                            close();
                            wait(refetch, 500);
                            if (
                                mutation === "deauthorizeDevice" ||
                                mutation === "restartDevice" ||
                                mutation === "unassign"
                            ) {
                                toast.success(t("operation-successful"));
                            }
                        }}
                    >
                        {t(buttonText)}
                    </Button>
                </div>
            }
        >
            {primaryText && (
                <p>
                    {t(primaryText, {
                        name,
                        roomNumber,
                    })}
                </p>
            )}
            {dangerousHTML && (
                <p
                    className="mt-4"
                    dangerouslySetInnerHTML={{
                        __html: t(dangerousHTML, {
                            name,
                            roomNumber,
                            location: roomTVName,
                        }),
                    }}
                ></p>
            )}
            {icon && (
                <div className="flex items-center gap-3 mt-4">
                    <Icon type={icon} size="2xl" />
                    <p>{t(iconText)}</p>
                </div>
            )}
            {secondaryText && <p className="mt-4">{t(secondaryText)}</p>}
        </Modal>
    );
};

const getDeviceFunctionText = (deviceFuntion) => {
    switch (deviceFuntion) {
        case "TV":
            return "tv-device";
        case "CAST":
            return "chromecast";
        case "TV+CAST":
            return "chromecast-and-stb";
        default:
            return "";
    }
};
