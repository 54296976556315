import React, { useEffect, useState } from "react";
import UseEditableField from "../../../../../components/useEditableField";
import { useTranslation } from "react-i18next";

import SessionDate from "../../../../../date";
import Select from "components/Select";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { changeActionValues } from "actions/globalActions";
import { useDispatch } from "react-redux";

const NewStayData = ({ roomId }) => {
    const [selectData, setSelectData] = useState({ options: [] });
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const roomsQuery = gql`
        {
            data: rooms(filter: { free: true }) {
                info {
                    count
                }
                results {
                    id
                    name
                }
            }
        }
    `;

    const [arrivalDate, setArrivalDate] = useState(null);

    function tick() {
        setArrivalDate(
            SessionDate().toLocaleString({
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            })
        );
    }
    useEffect(() => {
        const intervalId = setInterval(tick, 1000);
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    const [getOptionsQuery, { data, loading }] = useLazyQuery(roomsQuery, {
        fetchPolicy: "network-only",
    });

    useEffect(() => getOptionsQuery(), []);

    useEffect(() => {
        if (data) {
            setSelectData({
                options: data?.data?.results?.map((room) => {
                    return {
                        id: room?.id,
                        label: `${room?.name} ${room?.surname !== undefined ? room?.surname : ""}`,
                        roomsName: room?.rooms?.map((r) => (
                            <span className="text-xs text-gray-800 ml-4">{r.name}</span>
                        )),
                        value: room?.id,
                    };
                }),
            });
        }
    }, [data]);

    const handleChange = (value) => {
        dispatch(
            changeActionValues({
                ["room-number"]: value,
            })
        );
    };

    return (
        <div className="p-8">
            <div className="border-b border-gray-200 text-lg pb-2 flex justify-between">
                <div className="first-capital font-bold">{t("stay-information")}</div>
            </div>

            <div className="mt-5 grid gap-4 grid-cols-2">
                <div className="relative">
                    <div className="text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {t("arrival")}
                    </div>
                    <div className="text-gray-600 mt-2 py-2">{arrivalDate}</div>
                </div>
                <UseEditableField
                    data={{
                        label: "departure",
                        labelOnEdition: "departure",
                        value: "",
                        inEditionMode: true,
                        dateAdjust: "text-sm text-gray-600",
                        type: "date&time",
                        id: "new-stay-data-departure",
                    }}
                ></UseEditableField>
                <div className="col-span-2">
                    <label className="text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {t("room-number")} *
                    </label>
                    <Select
                        id="new-guest-form-select-number-room"
                        width="35%"
                        multiple={true}
                        options={selectData ? selectData?.options : []}
                        onChange={(value) => handleChange(value)}
                        placeholder={t("select-room")}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewStayData;
