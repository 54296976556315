import { useLazyQuery, useMutation } from "react-apollo";
import {
    ASSIGN_DEVICE,
    AUTHORIZE_ALL,
    AUTHORIZE_DEVICE,
    CHANGE_DEVICE_FUNCTION,
    DELETE_DEVICE,
    DESAUTHORIZE_DEVICE,
    DETACH_FROM_PROPERTY,
    EDIT_DEVICE_NAME,
    FACTORY_RESET,
    LAUNCH_SETTINGS_SCREEN,
    LAUNCH_SETTINGS_SYSTEM_SCREEN,
    MOVE_ROOM,
    PAIRING_REMOTE_CONTROL,
    RELOAD_INTERFACE,
    RESTART_DEVICE,
    ROOMS_WITHOUT_DEVICE,
    STATUS,
    SYNC_CHROMECAST,
    TURN_OFF_ON,
    UNASSIGN_DEVICE,
    UNINSTALL_TV,
} from "../utils/devicesQueries";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useModal } from "components/Modal";

export const useDevicesActions = () => {
    const [authorizeAllChromecast] = useMutation(AUTHORIZE_ALL);
    const [syncChromecast] = useMutation(SYNC_CHROMECAST);
    const [unassignChromecast] = useMutation(UNASSIGN_DEVICE);
    const [uptdateRoom] = useMutation(MOVE_ROOM);
    const [getRooms, { data: rooms, loading }] = useLazyQuery(ROOMS_WITHOUT_DEVICE);
    const [editDeviceName] = useMutation(EDIT_DEVICE_NAME);
    const [restartDeviceMutation] = useMutation(RESTART_DEVICE);
    const [statusMutation] = useMutation(STATUS);
    const [factoryResetMutation] = useMutation(FACTORY_RESET);
    const [desauthorizeMutation] = useMutation(DESAUTHORIZE_DEVICE);
    const [authoriseMutation] = useMutation(AUTHORIZE_DEVICE);
    const [deleteMutation] = useMutation(DELETE_DEVICE);
    const [assignMutation] = useMutation(ASSIGN_DEVICE);
    const [detachMutation] = useMutation(DETACH_FROM_PROPERTY);
    const [turnoffonMutation] = useMutation(TURN_OFF_ON);
    const [reloadInterfaceMutation] = useMutation(RELOAD_INTERFACE);
    const [uninstallTV] = useMutation(UNINSTALL_TV);
    const [changeFunction] = useMutation(CHANGE_DEVICE_FUNCTION);
    const [remotePairing] = useMutation(PAIRING_REMOTE_CONTROL);
    const [launchSettingsMutation] = useMutation(LAUNCH_SETTINGS_SCREEN);
    const [launchSystemSettingsMutation] = useMutation(LAUNCH_SETTINGS_SYSTEM_SCREEN);

    const { t } = useTranslation();
    const { close } = useModal();

    const executeDeviceMutation = (mutation, variables) => {
        mutation({
            variables,
        });
    };

    const unassignDevice = (ref) => executeDeviceMutation(unassignChromecast, { ref });
    const moveRoom = (ref, roomtvId, oldroomId = "") =>
        executeDeviceMutation(uptdateRoom, { ref, roomtvId, oldroomId });
    const editName = (ref, name) => executeDeviceMutation(editDeviceName, { ref, name });
    const restartDevice = (ref) => executeDeviceMutation(restartDeviceMutation, { ref });
    const toogleStatus = (ref, showWarnings) => executeDeviceMutation(statusMutation, { ref, showWarnings });
    const factoryReset = (ref) => executeDeviceMutation(factoryResetMutation, { ref });
    const deauthorizeDevice = (ref) => executeDeviceMutation(desauthorizeMutation, { ref });
    const authoriseDevices = (ref, mac, type, model, name, fn, roomtvId, assignCC) =>
        executeDeviceMutation(authoriseMutation, { ref, mac, type, model, name, function: fn, roomtvId, assignCC });
    const deleteDevice = (ref) => executeDeviceMutation(deleteMutation, { ref });
    const assignDevice = (ref, roomtvId, fn) => executeDeviceMutation(assignMutation, { ref, roomtvId, function: fn });
    const detachFromProperty = (ref) => executeDeviceMutation(detachMutation, { ref });
    const turnOffOnDevice = (ref, power) => executeDeviceMutation(turnoffonMutation, { ref, power });
    const reloadInterface = (ref, kind) => executeDeviceMutation(reloadInterfaceMutation, { ref, kind });
    const uninstallTVDevice = (ref) => executeDeviceMutation(uninstallTV, { ref });
    const changeDeviceFunction = (ref, fn) => executeDeviceMutation(changeFunction, { ref, function: fn });
    const pairingRemoteControl = (ref) => executeDeviceMutation(remotePairing, { ref });
    const launchSettingsScreen = (ref) => executeDeviceMutation(launchSettingsMutation, { ref });
    const launchSystemSettingsScreen = (ref) => executeDeviceMutation(launchSystemSettingsMutation, { ref });

    const authoriseAllChromecast = () => {
        authorizeAllChromecast();
        toast.success(t("operation-successful"));
        close();
    };

    const syncAllChromecast = () => {
        syncChromecast();
        toast.success(t("operation-successful"));
        close();
    };

    return {
        authoriseAllChromecast,
        syncAllChromecast,
        unassignDevice,
        getRooms,
        rooms,
        loading,
        moveRoom,
        editName,
        restartDevice,
        toogleStatus,
        factoryReset,
        deauthorizeDevice,
        authoriseDevices,
        deleteDevice,
        assignDevice,
        detachFromProperty,
        turnOffOnDevice,
        reloadInterface,
        uninstallTVDevice,
        changeDeviceFunction,
        pairingRemoteControl,
        launchSettingsScreen,
        launchSystemSettingsScreen,
    };
};
