import GeneralSettings from "../components/Section/Services/Sales/GeneralSettings";
import Shop from "../components/Section/Services/Sales/Shop";
import ShopSidebar from "../components/Sidebar/ShopSidebar";
import ShopSettings from "../components/Section/Services/Sales/ShopSettings";
import ShopIntegrations from "../components/Section/Services/Sales/ShopIntegrations";
import Products from "../components/Section/Services/Sales/Products";
import Labels from "../components/Section/Services/Sales/Labels";
import ProductSettings from "../components/Section/Services/Sales/ProductSettings";
import ShopCategories from "../components/Section/Services/Sales/ShopCategories";
import Extras from "../components/Section/Services/Sales/components/ShopSettings/ProductSettings/Extras/Extras";
import ExtrasTranslate from "../components/Section/Services/Sales/components/ShopSettings/ProductSettings/Extras/ExtrasTranslate";
import ShopAssignedUsers from "../components/Section/Services/Sales/ShopAssignedUsers";
import GeneralSettingsAutomaticMessagesEdition from "../components/Section/Services/Sales/GeneralSettingsAutomaticMessagesEdition";
import Channels from "../components/Section/Services/tvChannels/channels/Channels";
import ChannelEdition from "../components/Section/Services/tvChannels/channels/ChannelEdition";
import BulkChannels from "../components/Section/Services/tvChannels/channels/BulkChannels";
import GroupChannels from "../components/Section/Services/tvChannels/channels/GroupChannels";
import GroupChannelsEdit from "../components/Section/Services/tvChannels/channels/GroupChannelsEdit";
import Grids from "../components/Section/Services/tvChannels/channels/Grids";
import GridsEdition from "../components/Section/Services/tvChannels/channels/GridsEdition";
import ChannelsGeneralSettings from "../components/Section/Services/tvChannels/channels/ChannelsGeneralSettings";
import CorporateChannels from "../components/Section/Services/tvChannels/channels/CorporateChannels";
import { newRoute, newRedirect } from "./utils";
import CorporateChannelEdition from "../components/Section/Services/tvChannels/channels/CorporateChannelEdition";
import PresetChannels from "../components/Section/Services/tvChannels/channels/PresetChannels";
import OrderSummary from "../components/Section/Services/Sales/OrderSummary";
import OrderPreview from "../components/Section/Customers/Guest/GuesOrders.js/OrderPreview";
import EditShopCategoriesParent from "components/Section/Services/Sales/EditShopCategoriesParent";
import OrderMonitorContainer from "components/Section/Services/Sales/orders/monitor/OrderMonitorContainer";
import ChannelsImport from "components/Section/Services/tvChannels/channels/ChannelsImport";
import MoviesGeneralSettings from "components/Section/Services/Movies/MoviesGeneralSettings";
import WatchedMoviesList from "sections/services/movies/WatchedMoviesList";

const sectionID = "services";
const packageID = "services";

const attrSales = "productSALES";
const attrShopsConfig = "shopsGeneralconfig";
const attrShopsManager = "shopsManager";
const attrChannels = "channelList";
const attrChannelsGrid = "channelGrids";
const attrChannelGroups = "channelGroups";
const attrChannelSettings = "channelSettings";
const attrcorporateChannelsInGlobalManagement = "corporateChannelsInGlobalManagement";
const attrPredefinedChannels = "predefinedChannels";
const attrShopsOrders = "shopsOrders";
const attrMovieList = "movieList";
const attrMovieSettings = "movieSettings";

const sectionSalesID = "sales";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);

export const servicesRoutes = [
    route("/", {
        redirect: redirect("/sales/shop"),
    }),

    route("/sales", {
        redirect: redirect("/sales/shop"),
        attribute: attrSales,
    }),
    route("/sales/shop", {
        section: Shop,
        attribute: attrShopsManager,
    }),
    route("/sales/order-summary", {
        section: OrderSummary,
        attribute: attrShopsOrders,
    }),
    route("/sales/order-monitor/:shopId", {
        hideSidebar: true,
        hideHeader: true,
        section: OrderMonitorContainer,
        attribute: attrShopsOrders,
    }),
    route("/sales/order-monitor/preview/:id", {
        hideSidebar: true,
        hideHeader: true,
        section: OrderPreview,
        attribute: attrSales,
    }),
    route("/sales/shop/products/:id", {
        section: Products,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/settings/:id/*", {
        section: ShopSettings,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/integrations/:id", {
        section: ShopIntegrations,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/assigned-users/:id", {
        section: ShopAssignedUsers,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/categories/:id", {
        section: ShopCategories,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/categories/:id/edit-category/:idCat", {
        section: EditShopCategoriesParent,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/labels/:id", {
        section: Labels,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/:id/product/:productId", {
        section: ProductSettings,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/:id/product/:productId/extras", {
        section: Extras,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/shop/:id/product/:productId/extras/translate", {
        section: ExtrasTranslate,
        sidebar: ShopSidebar,
        sectionID: sectionSalesID,
        attribute: attrShopsManager,
    }),
    route("/sales/general-settings", {
        section: GeneralSettings,
        attribute: attrShopsConfig,
    }),
    route("/sales/general-settings/automatic-messages/:type", {
        section: GeneralSettingsAutomaticMessagesEdition,
        attribute: attrShopsConfig,
    }),
    route("/tv-channels", {
        redirect: redirect("/tv-channels/channels"),
        attribute: attrChannels,
    }),
    route("/tv-channels/channels", {
        section: Channels,
        attribute: attrChannels,
    }),
    route("/tv-channels/channels/import", {
        hideSidebar: true,
        hideHeader: true,
        section: ChannelsImport,
        attribute: attrChannels,
    }),
    route("/tv-channels/corporate-channels", {
        section: CorporateChannels,
        attribute: attrcorporateChannelsInGlobalManagement,
    }),
    route("/tv-channels/channel/:id", {
        section: ChannelEdition,
        attribute: attrChannels,
    }),
    route("/tv-channels/channels/add-bulk", {
        section: BulkChannels,
        attribute: attrChannels,
    }),

    route("/tv-channels/grids", {
        section: Grids,
        attribute: attrChannelsGrid,
    }),
    route("/tv-channels/corporate-channels/:id", {
        section: CorporateChannelEdition,
        attribute: attrcorporateChannelsInGlobalManagement,
    }),
    route("/tv-channels/grids/:gridId", {
        section: GridsEdition,
        attribute: attrChannelsGrid,
    }),
    route("/tv-channels/grids/:gridId/group/:groupId", {
        section: GroupChannelsEdit,
        attribute: attrChannelsGrid,
    }),
    route("/tv-channels/preset-channels", {
        section: PresetChannels,
        attribute: attrPredefinedChannels,
    }),
    route("/tv-channels/preset-channels/bulk-channels", {
        section: PresetChannels,
        attribute: attrPredefinedChannels,
    }),
    route("/tv-channels/groups", {
        section: GroupChannels,
        attribute: attrChannelGroups,
    }),
    route("/tv-channels/groups/:groupId", {
        section: GroupChannelsEdit,
        attribute: attrChannelGroups,
    }),

    route("/tv-channels/groups/:groupId/edit-channel/:id", {
        section: ChannelEdition,
        attribute: attrChannelGroups,
    }),

    route("/tv-channels/general-settings", {
        section: ChannelsGeneralSettings,
        attribute: attrChannelSettings,
    }),

    route("/movies/general-settings", {
        section: MoviesGeneralSettings,
        attribute: attrMovieList,
        check: ({ permissions }) => {
            const freeToguest = permissions?.movies?.freeToguest;
            const adultContent = permissions?.movies?.adult;
            return !freeToguest || adultContent;
        },
    }),
    route("/movies/watched-list", {
        section: WatchedMoviesList,
        attribute: attrMovieSettings,
    }),
];
