import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addFloorCommon, navToPrevious, updateCustomize } from "./FloorWings";
//API
import { useParams } from "react-router-dom";

//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseButton from "../../../useButton";
import RoomFields from "./RoomFields";
import UseEditableField from "../../../useEditableField";
import UseCheckBox from "../../../Inputs/useCheckBox";

//Utils
import UpdateTopology from "../../../../hooks/GraphqlCalls/Hotel/Settings/UpdateTopology";
import { validateSection } from "../../../../hooks/Utils/Validations";
import { toast } from "react-toastify";

//Actions
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { setActionValidations, cleanActionValidations } from "../../../../actions/globalActions";

//Utils
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import AddFloor from "./AddFloor";

const AddWing = () => {
    const { t } = useTranslation();
    //Actions
    const dispatch = useDispatch();
    const sectionName = `add-wing`;

    //Store

    const { data } = useSelector((state) => state.sectionContent);
    const { values, actionData } = useSelector((state) => state.action);

    //State
    const [customize, setCustomize] = useState(false);
    const [showFloorsFields, setShowFloorsFields] = useState(true);
    const [floorsNumber, setFloorsNumber] = useState(1);

    useEffect(() => {
        return () => {
            dispatch(cleanActionValidations());
        };
        // eslint-disable-next-line
    }, []);

    //Params
    const { id } = useParams();
    if (!data) {
        //window.location.href = `/hotel/property-settings/topology`;
    }

    let wingData = "";
    let wingLastFloorPos = "";
    let wingLastPos = "";

    if (data) {
        data.map((wing) => (parseInt(wing.id) === parseInt(id) ? (wingData = wing) : null));
        wingLastFloorPos =
            wingData.floors && wingData.floors.length > 0 ? wingData.floors[wingData.floors.length - 1].order : 0;
        wingLastPos = data && data.length > 0 ? parseInt(data[data.length - 1].pos) : 0;
    }

    const addFloor = () => {
        let floorsData = [];
        //Validate fields
        const validations = validateSection(`topology-add-wing${customize ? "-customize" : ""}`, values, t, {
            floorsNumber: floorsNumber,
        });

        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }

        //Prepare data
        for (let index = 0; index < floorsNumber; index++) {
            const suffix = customize ? `_${index + 1}` : "";
            let locationsData = [];
            if (values[`locations${suffix}`]) {
                for (let i = 1; i <= parseInt(values[`tvs-per-room${suffix}`]); i++) {
                    const nestedSuffix = `_${i}`;
                    locationsData.push({
                        name: values[`location${suffix}${nestedSuffix}`],
                        cec: values[`location-cec${suffix}${nestedSuffix}`],
                    });
                }
            }
            floorsData.push({
                name: customize
                    ? values[`floor-name${suffix}`]
                    : `${capitalizeFirst(t("floor"))} ${parseInt(wingLastFloorPos + index + 1)}`,
                numberOfRooms: customize ? values[`number-of-rooms${suffix}`] : values[`number-of-rooms`],
                starterRoom: customize
                    ? values[`starter-room${suffix}`]
                    : parseInt(`${data && data.length > 0 ? values["order"] : ""}${index + 1}01`),
                Locations: locationsData,
                order: customize ? values[`order${suffix}`] : parseInt(wingLastFloorPos + index + 1),
            });
        }

        dispatch(showGlobalLoading(true));
        dispatch(
            setExecuteQuery({
                action: "topology-add-wing",
                params: { wingId: id, floorsData: floorsData },
            })
        );
    };

    //Functions
    const printFloorToCustomise = (index) => {
        return (
            <div className="border inline-flex w-full  rounded border-gray-300 p-8 mt-4">
                <RoomFields
                    fieldsToShow={{
                        order: true,
                        floorName: true,
                        numberOfRooms: true,
                        starterRoom: true,
                        tvsPerRoom: true,
                        location: true,
                    }}
                    initialData={{
                        parentSectionName: sectionName,
                        roomNumber: 2,
                        order: wingLastFloorPos + index,
                        floorName: `${capitalizeFirst(t("floor"))} ${wingLastFloorPos + index}`,
                        starterRoom: parseInt(`${data && data.length > 0 ? values["order"] : ""}${index}01`),
                        numberOfRooms: parseInt(values["number-of-rooms"] ? values["number-of-rooms"] : 1),
                        tvsPerRoom: parseInt(values["tvs-per-room"] ? values["tvs-per-room"] : 1),
                        locationsSelected: values["location"] ? Object.values(values["location"]) : [],
                    }}
                    index={index}
                />
            </div>
        );
    };

    useEffect(() => {
        updateCustomize({ actionData, sectionName, setCustomize });
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        if (values["floors-number"]) {
            setFloorsNumber(parseInt(values["floors-number"]));
            setShowFloorsFields(values["floors-number"] === "0" ? false : true);
        }
        // eslint-disable-next-line
    }, [values]);

    return (
        <>
            <UpdateTopology />
            <UseSectionHeader title="add-wing" navToPreviousPage={true} />

            <div className="bg-white w-full p-8 ">
                <div className="float-left w-full">
                    <div className="w-1.5/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-order`,
                                label: "topology-order",
                                inEditionMode: true,
                                type: "number",
                                numberMin: 1,
                                numberMax: 20,
                                value: wingLastPos + 1,
                            }}
                        ></UseEditableField>
                    </div>
                    <div className="w-3/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-name`,
                                label: "wing-name",
                                inEditionMode: true,
                                type: "text",
                                value: `${capitalizeFirst(t("wing"))} ${wingLastPos + 1}`,
                            }}
                        ></UseEditableField>
                    </div>
                    <div className="w-2/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-pms-number`,
                                label: "pms-number",
                                inEditionMode: true,
                                type: "text",
                                value: "[room_number]",
                            }}
                        ></UseEditableField>
                    </div>
                    <div className="w-1.5/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-floors-number`,
                                label: "floors-number",
                                inEditionMode: true,
                                type: "number",
                                numberMin: 0,
                                numberMax: 20,
                                value: 1,
                            }}
                        ></UseEditableField>
                    </div>
                    {showFloorsFields ? (
                        <div className="float-left mt-6">
                            <UseCheckBox
                                checked={false}
                                id={`${sectionName}-customize-each-floor`}
                                name={"customize-each-floor"}
                                enabled={true}
                                translatable={true}
                            />
                        </div>
                    ) : null}
                </div>
                {!customize && showFloorsFields ? (
                    <div className="border inline-flex w-full  rounded border-gray-300 p-8 mt-4">
                        <RoomFields
                            fieldsToShow={{
                                numberOfRooms: true,
                                tvsPerRoom: true,
                                location: true,
                            }}
                            initialData={{
                                parentSectionName: sectionName,
                                numberOfRooms: 1,
                                tvsPerRoom: 1,
                            }}
                        />
                    </div>
                ) : (
                    [...Array(parseInt(floorsNumber))].map((e, i) => {
                        return printFloorToCustomise(i + 1);
                    })
                )}
                <div className="inline-flex w-full justify-end mt-4">
                    <UseButton
                        action={(e) => navToPrevious()}
                        buttonName={"cancel"}
                        buttonColor="btn-white"
                        adjust="float-left mr-6 "
                        id={`${sectionName}-cancel`}
                    />
                    <UseButton
                        action={(e) => addFloor()}
                        buttonName={"save"}
                        buttonColor="btn-blue"
                        adjust="float-left "
                        id={`${sectionName}-save`}
                    />
                </div>
            </div>
        </>
    );
};

export default AddWing;
