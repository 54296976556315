import React from "react";
import Button from "components/Button";
import Icon from "components/Icon";
import { LogOutUsers } from "../Monitoring/modals/LogOutUsers";

const Portals = (data, superUser, open, t) => {
    let portals = [
        {
            id: "hotelwifi",
            title: "wifi-hotel",
            icon: "wing",
            description: "WiFi signal offered to guests",
            adjust: "col-start-1 col-end-2",
            fields: [
                {
                    id: "hotelwifiname",
                    type: "input",
                    name: "wifi-name",
                    value: data.hotelwifi.results.name,
                },
                {
                    id: "hotelwifipass",
                    type: "input",
                    name: "password",
                    value: data.hotelwifi.results.password,
                },
            ],
            save: true,
        },
        {
            id: "wifiLicenses",
            title: "wifi-licenses",
            icon: "user-regular",
            description: "number-logged-users",
            fields: [
                {
                    id: "count",
                    type: "counter",
                    licenses: data.wifiLicenses.used,
                    total: data.wifiLicenses.available,
                },
            ],
            ...(superUser
                ? {
                      button: (
                          <Button id="log out" design="blue" onClick={() => open(<LogOutUsers />)}>
                              <Icon type="superuser" size="xl" />
                              <p>{t("reset-wifi-licenses")}</p>
                          </Button>
                      ),
                  }
                : {}),
        },
    ];

    return portals;
};
export default Portals;
