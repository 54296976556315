import { Session } from "./Session";
import { parseBoolean } from "./Utils";

import { ROLE as r, PERMISSION as p, validatePermission, validateRole } from "constants/permissions";

const ROLE = new Proxy(r, {
    get: function (target, name) {
        if (!Object.keys(target).includes(name)) {
            console.error(`ROLE.${name} not found`);
        }
        return target[name] || null;
    },
});

const PERMISSION = new Proxy(p, {
    get: function (target, name) {
        if (!Object.keys(target).includes(name)) {
            console.error(`PERMISSION.${name} not found`);
        }
        return target[name] || null;
    },
});

const ComposePermissionsTree = (
    permissions, // When is global management, global permissions, otherwise active project permissions
    userAccesses, // current user roles accesses (only filled when !isSuperUser)
    userManagerRole, // chain manager
    isSuperUser, // if is super user
    chainPerms, // available permissions in any chain project (only filled when isGlobalManagement)
    hasCorporateUser // if has global management
) => {
    const isGlobalManagement = Session.getSessionProp("projectRef") === "CORPORATE";
    const isESChain = parseBoolean(Session.getSessionProp("ESChain"));
    const forceAllPermissions = Session.allPermissions();

    const projectPermissions = isGlobalManagement ? null : permissions;
    const globalPermissions = isGlobalManagement ? permissions : null;

    // Check if has the permission in the active project or global mode
    const hasPermission = (id) => {
        if (!validatePermission(id)) {
            return false;
        }
        if (isGlobalManagement) {
            return hasGlobalPermissions(id);
        } else {
            return hasProjectPermissions(id);
        }
    };

    // Check if has the permission in global mode
    const hasGlobalPermissions = (id) => {
        if (!validatePermission(id)) {
            return false;
        }
        return globalPermissions ? globalPermissions.includes(id) : false;
    };

    // Check if has the permission in the active project
    const hasProjectPermissions = (id) => {
        if (!validatePermission(id)) {
            return false;
        }
        return projectPermissions ? projectPermissions.includes(id) : false;
    };

    // Check if has the permission in the chain project (global mode only)
    const hasChainPermission = (id) => {
        if (!validatePermission(id)) {
            return false;
        }
        if (!isGlobalManagement) {
            // If not global management, chain permissions are not available
            return false;
        }
        return chainPerms?.includes(id);
    };

    // Check if has the permission in the active project or global mode including chain permissions
    const hasPermissionInAnyProject = (id) => {
        if (isGlobalManagement) {
            return hasGlobalPermissions(id) || hasChainPermission(id);
        } else {
            return hasProjectPermissions(id);
        }
    };

    const hasRoleInProject = (id) => {
        const existsPermission = Object.values(PERMISSION).includes(id);
        return hasRole(id) && (!existsPermission || hasPermission(id));
    };

    const hasRole = (id) => {
        if (!validateRole(id)) {
            return false;
        }
        return isSuperUser || userManagerRole || userAccesses?.includes(id);
    };

    const hotelCastConfig = hasRoleInProject(ROLE.castConfig);
    const hotelTopology =
        !isGlobalManagement &&
        (isSuperUser || (hasRoleInProject(ROLE.topology) && !hasPermission(PERMISSION.legacyTV)));
    const hotelTvSettings = hasRoleInProject(ROLE.tvConfig);

    const adminShops =
        hasPermission(PERMISSION.productSALES) &&
        !isGlobalManagement &&
        hasRole(ROLE.shopsAdministrator) &&
        hasPermission(PERMISSION.shopsAdministrator);

    const manageShops =
        hasPermission(PERMISSION.productSALES) && (adminShops || hasRole(ROLE.shopsManager)) && !isGlobalManagement;

    const manageOrders =
        hasPermission(PERMISSION.productSALES) &&
        !isGlobalManagement &&
        (manageShops || hasRole(ROLE.shopsOrders)) &&
        hasPermission(PERMISSION.shopsOrders);

    return {
        product: {
            advancedDesign: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productADVANCEDDESIGN),
            cast: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productCAST),
            chain: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productCHAIN),
            channels: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productCHANNELS),
            corporate: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productCORPORATE),
            guest: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productGUEST),
            house: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productHOUSE),
            mobile: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productMOBILE),
            sales: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productSALES),
            tv: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productTV),
            movies: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productONDEMAND),
            wifi: forceAllPermissions || hasPermissionInAnyProject(PERMISSION.productWIFI),
            salesPremium:
                forceAllPermissions ||
                (hasPermissionInAnyProject(PERMISSION.productSALES) && hasPermissionInAnyProject(PERMISSION.myOrders)), // SO_SALE+
            tvPremium:
                forceAllPermissions ||
                (hasPermissionInAnyProject(PERMISSION.productTV) && hasPermissionInAnyProject(PERMISSION.optTVPremium)), // SO_TVDE+
            legacy:
                hasPermissionInAnyProject(PERMISSION.legacyWIFI) ||
                hasPermissionInAnyProject(PERMISSION.legacyTV) ||
                hasPermissionInAnyProject(PERMISSION.legacyMOBILE) ||
                hasPermissionInAnyProject(PERMISSION.legacyGUEST) ||
                hasPermissionInAnyProject(PERMISSION.legacySALES),
        },
        integrations: {
            pos: forceAllPermissions || hasPermission(PERMISSION.posIntegration),
        },
        hotel: {
            map: hasRole(ROLE.map) && hasPermission(PERMISSION.map),
            monitoring: hasRole(ROLE.monitoring) && hasPermission(PERMISSION.monitoring),
            networkAlerts:
                (hasRole(ROLE.networkAlerts) && hasPermission(PERMISSION.networkAlerts)) ||
                (isSuperUser && !hasPermission(PERMISSION.networkAlerts) && !isGlobalManagement),
            statusMail: hasRole(ROLE.statusMail) && hasPermission(PERMISSION.statusMail),
            roomList: hasRole(ROLE.roomList) && hasPermission(PERMISSION.roomList),
            roomGroups: hasRole(ROLE.roomGroups) && hasPermission(PERMISSION.roomGroups),
            castConfig: hotelCastConfig,
            topology: hotelTopology,
            tvInputs: hasRole(ROLE.tvInputs) && hasPermission(PERMISSION.tvInputs),
            TVSettings: hotelTvSettings,
            wifiSettings: hotelCastConfig || hotelTopology || hotelTvSettings,
            wifiConfig: hasRole(ROLE.wifiConfig) && hasPermission(PERMISSION.wifiConfig),
            mobileConfig: hasRole(ROLE.mobileConfig) && hasPermission(PERMISSION.mobileConfig),
            nativeApps: hasPermission(PERMISSION.nativeApps) && !isGlobalManagement && hasRole(ROLE.mobileConfig),
        },
        allProperties: {
            mobileConfig: hasRole(ROLE.mobileConfig) && isGlobalManagement,
            manageProperties: hasRole(ROLE.manageProperties) && isGlobalManagement,
            manageBrands: hasRole(ROLE.manageBrands) && isGlobalManagement,
            nativeApps:
                hasChainPermission(PERMISSION.mobileConfig) &&
                hasChainPermission(PERMISSION.nativeApps) &&
                hasRole(ROLE.mobileConfig),
        },
        design: {
            interfacePreview: hasPermission(PERMISSION.interfacePreview),
            interfaceEditor: hasRole(ROLE.interfaceEditor) && hasPermission(PERMISSION.interfaceEditor),
            multipleInterface: hasRole(ROLE.interfaceEditor) && hasPermission(PERMISSION.multipleInterface),
            corporateIdentity: hasPermission(PERMISSION.corporateIdentity),
            library: hasRole(ROLE.library) && hasPermission(PERMISSION.library),
            libraryVideos: hasPermission(PERMISSION.libraryVideos) || hasChainPermission(PERMISSION.libraryVideos),
            templates: isSuperUser && isESChain,
            appearances: hasRole(ROLE.appearancesEditor) && hasPermission(PERMISSION.appearancesEditor),
            tags: isGlobalManagement && hasRole(ROLE.interfaceEditor) && hasPermission(PERMISSION.interfaceEditor),
            apps: hasPermission(PERMISSION.apps) || hasChainPermission(PERMISSION.apps),
            mobileAppearance:
                hasRole(ROLE.mobileConfig) &&
                (hasPermission(PERMISSION.mobileConfig) || hasChainPermission(PERMISSION.mobileConfig)),
        },
        guests: {
            guestList: hasRole(ROLE.guestList) && hasPermission(PERMISSION.guestList),
            guestGroups: hasRole(ROLE.guestGroups) && hasPermission(PERMISSION.guestGroups),
            guestConfig: hasRole(ROLE.guestSettings) && hasPermission(PERMISSION.guestSettings),
            loyaltyCustomers:
                (!isGlobalManagement && hasRole(ROLE.loyaltyCustomers) && hasPermission(PERMISSION.loyaltyCustomers)) ||
                (isGlobalManagement &&
                    hasRole(ROLE.loyaltyCustomers) &&
                    hasPermissionInAnyProject(PERMISSION.loyaltyCustomers)),
            digitalKey: hasPermission(PERMISSION.digitalKey),
            messages:
                hasRole(ROLE.messaging) &&
                hasPermission(PERMISSION.messaging) &&
                (hasPermissionInAnyProject(PERMISSION.productTV) ||
                    hasPermissionInAnyProject(PERMISSION.productMOBILE)),
            alarms:
                hasRole(ROLE.alarms) &&
                hasPermission(PERMISSION.alarms) &&
                hasPermissionInAnyProject(PERMISSION.productTV),
            welcomeScreens:
                (!isGlobalManagement && hasPermission(PERMISSION.welcomeScreens)) ||
                (isGlobalManagement && hasChainPermission(PERMISSION.welcomeScreens)),
            wifi: hasPermission(PERMISSION.productWIFI),
            wifiPasscodes: hasRole(ROLE.wifiPasscodes) && hasPermission(PERMISSION.wifiPasscodes),
            guestSatisfaction: hasPermission(PERMISSION.guestSatisfaction) && Session.develMode(),
            preCheckin:
                hasRole(ROLE.guestList) && hasPermission(PERMISSION.guestList) && hasPermission(PERMISSION.preCheckin),
            messageSendLater: hasPermission(PERMISSION.messageSendLater),
        },
        stats: {
            cast: hasRole(ROLE.statsCast) && hasPermission(PERMISSION.statsCast),
            wifi: hasRole(ROLE.statsWifi) && hasPermission(PERMISSION.statsWifi),
            mobile: hasRole(ROLE.statsMobile) && hasPermission(PERMISSION.statsMobile),
            sales: hasRole(ROLE.statsSales) && hasPermission(PERMISSION.statsSales),
            internal: isSuperUser,
        },
        goOutSafe: {
            bookings: hasRole(ROLE.bookings) && hasPermission(PERMISSION.bookings),
            capacity: hasRole(ROLE.capacity) && hasPermission(PERMISSION.capacity),
            cleaning: hasRole(ROLE.cleaning) && hasPermission(PERMISSION.cleaning),
            staff: hasRole(ROLE.staff) && hasPermission(PERMISSION.staff),
            areas: hasRole(ROLE.zonesConfig) && hasPermission(PERMISSION.zonesConfig),
            categories: hasRole(ROLE.zonesConfig) && hasPermission(PERMISSION.zonesConfig),
        },
        managerSetup: {
            manageUsers: hasRole(ROLE.manageUsers),
            manageLanguages: hasRole(ROLE.manageLanguages),
            manageRoles: hasRole(ROLE.manageRoles),
            staff: hasRole(ROLE.staff) && hasPermission(PERMISSION.staff) && !isGlobalManagement,
            networkManagement: hasRole(ROLE.networkManagement),
            networkAdmin: hasRole(ROLE.networkAdmin),
            wifiOrMobile: hasChainPermission(PERMISSION.productWIFI) || hasChainPermission(PERMISSION.productMOBILE),
            taxManagement:
                hasRole(ROLE.taxManagement) && hasPermissionInAnyProject(PERMISSION.taxManagement) && hasCorporateUser,
        },
        legacy: {
            wifi:
                (hasRole(ROLE.networkManagement) || hasRole(ROLE.networkAdmin)) &&
                hasPermissionInAnyProject(PERMISSION.legacyWIFI),
            tv: hasRole(ROLE.legacyAdmin) && hasPermissionInAnyProject(PERMISSION.legacyTV),
            // Legacy sales can't coexist with cloud sales, so in development mode (where perms are always true) we force it to be false
            sales:
                hasRole(ROLE.legacyAdmin) && hasPermissionInAnyProject(PERMISSION.legacySALES) && !Session.develMode(),
            mobile: hasRole(ROLE.legacyAdmin) && hasPermissionInAnyProject(PERMISSION.legacyMOBILE),
            guest: hasRole(ROLE.legacyAdmin) && hasPermissionInAnyProject(PERMISSION.legacyGUEST),
        },
        sales: {
            wdgParental: hasPermission(PERMISSION.wdgParental),
        },
        movies: {
            freeToguest: hasPermission(PERMISSION.freeToguest),
            hollywood: hasPermission(PERMISSION.hollywoodContent),
            adult: hasPermission(PERMISSION.adultContent),
        },
        services: {
            productSALES: !isGlobalManagement && hasPermission(PERMISSION.productSALES),
            productONDEMAND: !isGlobalManagement && hasPermission(PERMISSION.productONDEMAND),
            shopsAdministrator: adminShops,
            shopsManager: manageShops,
            shopsOrders: manageOrders,
            shopsGeneralconfig:
                (!isGlobalManagement &&
                    hasRole(ROLE.shopsGeneralconfig) &&
                    hasPermission(PERMISSION.shopsGeneralconfig)) ||
                (isGlobalManagement &&
                    hasRole(ROLE.shopsGeneralconfig) &&
                    hasChainPermission(PERMISSION.shopsGeneralconfig)),
            myOrders: hasPermissionInAnyProject(PERMISSION.myOrders),
            channelList:
                !isGlobalManagement && hasPermissionInAnyProject(PERMISSION.channelList) && hasRole(ROLE.channelList),
            channelGroups:
                !isGlobalManagement &&
                hasPermissionInAnyProject(PERMISSION.channelGroups) &&
                hasRole(ROLE.channelGroups),
            channelGrids:
                !isGlobalManagement && hasPermissionInAnyProject(PERMISSION.channelGrids) && hasRole(ROLE.channelGrids),
            multipleGrids: !isGlobalManagement && hasPermissionInAnyProject(PERMISSION.multipleGrids),
            corporateChannelsInGlobalManagement:
                isGlobalManagement &&
                hasRole(ROLE.corporateChannels) &&
                hasChainPermission(PERMISSION.corporateChannels) &&
                hasCorporateUser,
            corporateChannelsWithoutGlobalManagement: hasRole(ROLE.corporateChannels) && !hasCorporateUser,
            predefinedChannels: isSuperUser && hasPermissionInAnyProject(PERMISSION.productCHANNELS),
            checkoutInterface: hasPermissionInAnyProject(PERMISSION.checkoutInterface),
            channelSettings:
                !isGlobalManagement &&
                hasPermissionInAnyProject(PERMISSION.channelSettings) &&
                hasRole(ROLE.channelSettings),
            corporateChannels:
                (!isGlobalManagement &&
                    hasRole(ROLE.corporateChannels) &&
                    hasPermission(PERMISSION.corporateChannels)) ||
                (isGlobalManagement &&
                    hasRole(ROLE.corporateChannels) &&
                    hasChainPermission(PERMISSION.corporateChannels)),
            highAvailability: hasPermission(PERMISSION.highAvailability),
            movieList: hasPermission(PERMISSION.movieList),
            movieSettings: hasPermission(PERMISSION.movieSettings),
        },
        widgets: [
            hasPermissionInAnyProject(PERMISSION.wdgText) ? "TEXT" : null,
            hasPermissionInAnyProject(PERMISSION.wdgButton) ? "BUTTON" : null,
            hasPermissionInAnyProject(PERMISSION.wdgLogo) ? "LOGO" : null,
            hasPermissionInAnyProject(PERMISSION.wdgImage) ? "IMAGE" : null,
            hasPermissionInAnyProject(PERMISSION.wdgVideo) ? "VIDEO" : null,
            hasPermissionInAnyProject(PERMISSION.wdgCarrousel) ? "CAROUSEL" : null,
            hasPermissionInAnyProject(PERMISSION.wdgIcon) ? "ICON" : null,
            hasPermissionInAnyProject(PERMISSION.wdgWeather) ? "WEATHER" : null,
            hasPermissionInAnyProject(PERMISSION.wdgClock) ? "CLOCK" : null,
            hasPermissionInAnyProject(PERMISSION.wdgCalendar) ? "CALENDAR" : null,
            hasPermissionInAnyProject(PERMISSION.wdgMessages) ? "MESSAGES" : null,
            hasPermissionInAnyProject(PERMISSION.wdgQrcode) ? "QR" : null,
            hasPermissionInAnyProject(PERMISSION.wdgIframe) ? "IFRAME" : null,
            hasPermissionInAnyProject(PERMISSION.wdgHtml) ? "HTML" : null,
            hasPermissionInAnyProject(PERMISSION.wdgContainer) ? "CONTAINER" : null,
            hasPermissionInAnyProject(PERMISSION.wdgChat) ? "CHATBUTTON" : null,
            hasPermissionInAnyProject(PERMISSION.wdgAlarm) ? "ALARM" : null,
            hasPermissionInAnyProject(PERMISSION.wdgLogin) ? "WIFILOGIN" : null,
            hasPermissionInAnyProject(PERMISSION.interfaceMenu) ? "TVMENU" : null,
            hasPermissionInAnyProject(PERMISSION.wdgNavigElements) ? "TVMENUNAV" : null,
            hasPermissionInAnyProject(PERMISSION.wdgSlider) ? "SLIDER" : null,
        ],
        actions: {
            tvInputs: hasPermissionInAnyProject(PERMISSION.actTvinputs),
            bill: hasPermissionInAnyProject(PERMISSION.actBill),
            areas: hasPermissionInAnyProject(PERMISSION.actAreas),
            bookings: hasPermissionInAnyProject(PERMISSION.actMybookings),
            pos: hasPermissionInAnyProject(PERMISSION.actPos),
            surverys: hasPermissionInAnyProject(PERMISSION.actSurveys),
            checkout: hasPermissionInAnyProject(PERMISSION.actCheckout),
            channel: hasPermissionInAnyProject(PERMISSION.actChannel),
            listChannels: hasPermissionInAnyProject(PERMISSION.actListChannels),
            sales: hasPermissionInAnyProject(PERMISSION.actSales),
            actParental: hasPermissionInAnyProject(PERMISSION.actParental),
            customQr: hasPermissionInAnyProject(PERMISSION.customQr),
            actFeedback: hasPermissionInAnyProject(PERMISSION.actFeedback),
        },
        tags: [
            hasPermissionInAnyProject(PERMISSION.tagRoomnumber) ? "roomNumber" : null,
            hasPermissionInAnyProject(PERMISSION.tagRoomname) ? "roomName" : null,
            hasPermissionInAnyProject(PERMISSION.tagGuesttitle) ? "guestTitle" : null,
            hasPermissionInAnyProject(PERMISSION.tagGuestname) ? "guestName" : null,
            hasPermissionInAnyProject(PERMISSION.tagGuestsurname) ? "guestSurname" : null,
            hasPermissionInAnyProject(PERMISSION.tagGuestfullname) ? "guestFullname" : null,
            hasPermissionInAnyProject(PERMISSION.tagGuestgroup) ? "guestGroup" : null,
            hasPermissionInAnyProject(PERMISSION.tagChekcoutdate) ? "checkoutDate" : null,
            hasPermissionInAnyProject(PERMISSION.tagPairingurl) ? "pairingURL" : null,
            hasPermissionInAnyProject(PERMISSION.tagRoomcode) ? "pairingCode" : null,
            hasPermissionInAnyProject(PERMISSION.tagWifissid) ? "wifiSSID" : null,
            hasPermissionInAnyProject(PERMISSION.tagWifipassword) ? "wifiPassword" : null,
            hasPermissionInAnyProject(PERMISSION.tagChromecastname) ? "CCName" : null,
        ],
    };
};
export default ComposePermissionsTree;
