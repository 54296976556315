import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setExecuteQuery } from "../../../../../../../actions/sectionActions";
import {
    UPDATE_ENABLED_ALLERGENS,
    UPDATE_PRODUCT_ALLERGENS_AND_LABELS,
} from "../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { allergensPrefix, labelsPrefix } from "../../../../../../../hooks/Utils/SalesUtils";
import { Session } from "../../../../../../../hooks/Utils/Session";
import ReactTooltip from "components/ReactTooltip";
import UseSwitch from "../../../../../../Inputs/useSwitch";
import { SalesContextDeprecated } from "contexts/Sales";
import { GlobalContext } from "contexts/Global";
import Select from "components/Select";
import Button from "components/Button";
import Icon from "components/Icon";

function ProductSettingsAllergensCard({ data }) {
    const dispatch = useDispatch();

    //Consts&states
    const { productId } = data;
    const { t } = useTranslation();

    const { lang } = useContext(GlobalContext);

    const actionData = useSelector((state) => state.action);
    const { data: dataContext } = useContext(SalesContextDeprecated);
    const { executeQuery, refreshData } = useSelector((state) => state.sectionContent);
    const [loading, setLoading] = useState(true);
    const [allergensSelected, setAllergensSelected] = useState(null);
    const [labelsSelected, setLabelsSelected] = useState([]);
    const [otherFacetValuesSelected, setOtherFacetValuesSelected] = useState(null);
    const [allergens, setAllergens] = useState(null);
    const [enabledAllergens, setEnabledAllergens] = useState({ id: null, val: false });
    const [labels, setLabels] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const sectionName = "product-settings-allergens";
    const inputsName = [`${sectionName}-allergens`, `${sectionName}-labels`];

    //queries&mutations
    useEffect(() => {
        if (dataContext?.allergens && dataContext?.labels && dataContext?.channel) {
            dataSetter(dataContext);
        }
    }, [dataContext]);

    useEffect(() => {
        if (loading && !refreshData) {
            if (!initialLoad) {
                setLoading(false);
            } else {
                setInitialLoad(false);
            }
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!refreshData) {
            if (executeQuery?.action && executeQuery.action === UPDATE_PRODUCT_ALLERGENS_AND_LABELS) {
                setLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [refreshData]);

    //arrangeData
    const arrangeData = (data) => {
        let allergens = [];
        let labels = [];
        let allergensSelected = [];
        let labelsSelected = [];
        let otherFacetValuesSelected = [];

        if (data) {
            let facet = null;
            let channelToken = data.channel ? data.channel.token : null;
            const labelsCode = `${labelsPrefix}_${channelToken}`;
            if (data?.product?.facetValues && data.product.facetValues.length > 0) {
                data.product.facetValues.forEach((facetValue) => {
                    if (facetValue?.facet?.code === allergensPrefix()) {
                        allergensSelected.push(facetValue.id);
                    } else if (facetValue?.facet?.code === labelsCode) {
                        labelsSelected.push(facetValue.id);
                    } else {
                        otherFacetValuesSelected.push(facetValue.id);
                    }
                });
            }
            if (data?.allergens?.items && data.allergens.items.length > 0) {
                facet = data.allergens.items[0];
                if (facet?.values && facet.values.length > 0) {
                    facet.values.forEach((item) => {
                        allergens.push({
                            value: item.id,
                            label: getFacetName(item.translations, lang),
                        });
                    });
                }
            }

            if (data?.productVariants?.items.length > 0) {
                const id = data?.productVariants?.items?.[0]?.id;
                const enabledAllergens = data?.productVariants?.items?.[0]?.customFields?.are_allergens_enabled
                    ? true
                    : false;
                setEnabledAllergens({ id: id, val: enabledAllergens });
            }

            if (data?.labels?.items && data.labels.items.length > 0) {
                facet = data.labels.items.filter((item) => item.code === labelsCode);
                if (facet && facet.length > 0 && facet[0].values && facet[0].values.length > 0) {
                    facet[0].values.forEach((item) => {
                        labels.push({
                            value: item.id,
                            label: getFacetName(item.translations, lang),
                        });
                    });
                }
            }
        }
        return { allergens, labels, allergensSelected, labelsSelected, otherFacetValuesSelected };
    };

    const dataSetter = (data) => {
        let allergens = [];
        let labels = [];
        let allergensSelecetd = [];
        let labelsSelected = [];
        let otherFacetValuesSelected = [];
        setLoading(true);
        if (data) {
            const {
                allergens: allergensList,
                labels: labelsList,
                allergensSelected: allergensSelectedList,
                labelsSelected: labelsSelectedList,
                otherFacetValuesSelected: otherFacetValuesSelectedList,
            } = arrangeData(data);
            allergens = allergensList;
            labels = labelsList;
            labelsSelected = labelsSelectedList;
            allergensSelecetd = allergensSelectedList;
            otherFacetValuesSelected = otherFacetValuesSelectedList;
        }
        Session.setSessionProp("updateSelectOptions", inputsName.join(","));
        setAllergens(allergens);
        setLabels(labels);
        setLabelsSelected(labelsSelected);
        setAllergensSelected(allergensSelecetd);
        setOtherFacetValuesSelected(otherFacetValuesSelected);
        setLoading(false);
    };

    const getFacetName = (translations, defaultLang) => {
        let response = "";
        if (defaultLang && translations && translations.length > 0) {
            let translation = translations.filter(
                (translationData) => translationData.languageCode && translationData.languageCode === defaultLang
            );
            response = translation && translation.length > 0 ? translation[0].name : "";
            if (!response) response = translations[0].name;
        }
        return response;
    };
    const updateAllergensAndLabels = (removeFacets) => {
        let facetValueIds = otherFacetValuesSelected ? otherFacetValuesSelected : [];

        let allergensSelectedValues = actionData.values[`${inputsName[0]}-name`];
        let labelsSelectedValues = actionData.values[`${inputsName[1]}-name`];
        if (allergensSelectedValues) facetValueIds = facetValueIds.concat(allergensSelectedValues);
        else facetValueIds = facetValueIds.concat(allergensSelected);
        if (labelsSelectedValues) facetValueIds = facetValueIds.concat(labelsSelectedValues);
        else facetValueIds = facetValueIds.concat(labelsSelected);
        if (removeFacets) {
            facetValueIds = facetValueIds.filter(function (facet) {
                return removeFacets.indexOf(facet) < 0;
            });
        }
        setLoading(true);
        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_ALLERGENS_AND_LABELS,
                params: {
                    id: productId,
                    facetValueIds: facetValueIds,
                },
            })
        );
    };
    const handleCloseSelect = (isVisible, disabled) => {
        if (!loading && !isVisible && !disabled) {
            updateAllergensAndLabels();
        }
    };
    const handleRemoveOption = (value) => {
        if (!loading) {
            updateAllergensAndLabels([value]);
        }
    };

    //response
    return (
        <div className="w-full my-2 " style={{ height: "40rem" }}>
            <div className="w-full text-left mb-2 flex items-center ">
                <span className="font-bold text-gray-900 text-xl">{t("allergens")}</span>

                <UseSwitch
                    adjust={!enabledAllergens?.id ? "hidden" : ""}
                    checked={enabledAllergens.val}
                    disabled={loading}
                    action={() => {
                        dispatch(
                            setExecuteQuery({
                                action: UPDATE_ENABLED_ALLERGENS,
                                params: {
                                    enabled: !enabledAllergens.val,
                                    id: enabledAllergens.id,
                                },
                            })
                        );
                        setLoading(true);
                        setEnabledAllergens({ ...enabledAllergens, val: !enabledAllergens });
                    }}
                />
            </div>
            <div className="w-full text-left mb-5">
                <span className="block mb-3">{t("select-product-allergens")}</span>
                <Select
                    id={`${inputsName[0]}-id`}
                    allowUnselect={true}
                    options={allergens}
                    value={allergensSelected}
                    placeholder={t("select-allergens")}
                    multiple={true}
                    onChange={(value) => {
                        setAllergensSelected(value);
                    }}
                    disabled={loading}
                    onOpenChange={(value) => {
                        handleCloseSelect(value, loading);
                    }}
                />
            </div>
            <div className="w-full text-left">
                <span className="font-bold text-gray-900 text-xl block mb-2">
                    {t("{{capitalize, capitalize}}", {
                        capitalize: t("labels"),
                    })}
                </span>
                <div className=" mb-3">
                    {t("select-labels-like-show-product-details")}
                    <span
                        data-tip={t("each-product-can-have-a-maxium-labels", { number: 2 })}
                        className="ml-3 relative inline-block text-2xl text-gray-700"
                    >
                        <div
                            className=" icon icon-info "
                            style={{ position: "absolute", top: "-15px", left: "0" }}
                        ></div>
                    </span>
                </div>
                <Select
                    id={`${inputsName[1]}-id`}
                    allowUnselect={true}
                    options={labels}
                    value={labelsSelected}
                    placeholder={t("select-labels")}
                    multiple={true}
                    disabled={labelsSelected?.length >= 2 || loading}
                    onChange={(value) => {
                        // setUserChange(true);
                        setLabelsSelected(value);
                    }}
                    showSelectedItems={false}
                    onOpenChange={(value) => {
                        handleCloseSelect(value, loading);
                    }}
                />
                <div className=" w-full space-x-4 ">
                    {labelsSelected.map((label, index) => {
                        const name = labels.find((l) => l.value === label)?.label || "";
                        return (
                            <div className=" bg-blue-200 mt-4  w-auto inline-flex text-white rounded px-2 py-1">
                                {name}
                                <Button
                                    onClick={() => {
                                        setLabelsSelected(labelsSelected.filter((item) => item !== label));
                                        handleRemoveOption(label);
                                    }}
                                    disabled={loading}
                                    className=" pl-5"
                                    id={`remove-button-${index}`}
                                >
                                    <Icon type="remove" />
                                </Button>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                borderColor="#D3DAE1"
            />
        </div>
    );
}

export default ProductSettingsAllergensCard;
